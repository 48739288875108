
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';
import WellSignalsViewMixin from '@/components/tasqs/WellSignalsViewMixin';


@Component({
  components: {
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    eventDetail: () => getComponent('tasqs/TasqEventSummaryPopup'),
    TasqLightningChart: () => getComponent('tasqs/TasqLightningChart'),
    TasqPlungerLightningChart: () =>
      getComponent('tasqs/TasqPlungerLightningChart'),
    TasqLightningProductionChart: () =>
      getComponent('tasqs/TasqLightningProductionChart'),
    ChartLegend: () => getComponent('tasqs/charts/ChartLegend'),
    ChartFailureView: () => getComponent('tasqs/charts/ChartFailureView'),
    ChartHeader: () => getComponent('tasqs/charts/ChartHeader'),
    ChartTimeRange: () => getComponent('tasqs/charts/ChartTimeRange'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
  },
})
export default class WellSignalsView extends mixins(
  WellSignalsViewMixin,
) {


  get offTartgetStartDate(){
    if(this.activeTasq && this.activeTasq.offTargetStartDate  && this.activeTasq.predictionTypes && this.activeTasq.predictionTypes.includes('Off-Target RT') ){
      return this.activeTasq.offTargetStartDate
    }

    return ''

  }
}
